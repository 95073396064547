import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { device } from "../../Layout/GlobalStyle";

export const Container = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: column;
`;

export const StyledTypography = styled(Typography)`
  color: ${(props) => props.theme.primaryTextColor};
  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : "initial;"};
  ${(props) =>
    props.textDecoration === "underline" ? "cursor: pointer;" : ""};
    font-size: 2.3rem;
    font-weight: normal;

    @media ${device.mobileL}{
      font-size: 18pt;
    }
`;

export const BreadTypography = styled(Typography)`
  line-height: 1.4;
  font-size: 13px !important;

  @media ${device.mobileL}{
    font-size: 10pt !important;
  }
`;

export const StyledBreadCrumb = styled(Breadcrumbs)``;