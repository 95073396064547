import axios from "./axios";
import { getHeaders } from "../components/Layout";


export const getHerbs = (query) => {
    return axios.get(`/api/herbs${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getHerbMainPictureByHerbID = (herbid) => {
    return axios.get(`/api/herbs/main-picture/${herbid}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getHerbRatingByHerbID = (herbid) => {
    return axios.get(`/api/herbs/rating/${herbid}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const getHerbsByAilmentID = (ailmentid, query) => {
    console.log(ailmentid, 'this si ailment id in axios call')
    return axios.get(`/api/herbs/ailment/${ailmentid}${query? query: ''}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const getRecentlyViewedHerbs = (query) => {
    return axios.get(`/api/herbs/recently-viewed${query? query: ""}`, getHeaders())
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const getHerbsByHerbFamilyID = (herbFamilyID, query) => {
    return axios.get(`/api/herbs/by-herb-family/${herbFamilyID}${query? query: ""}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}

export const getHerbFavoriteCount = (herbid) => {
    return axios.get(`/api/herbs/favorite-count/${herbid}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}


export const getHerbBySlug = (slug) => {
    return axios.get(`/api/herbs/slug/${slug}`)
        .then(res => res)
        .catch(err => {
            throw err
        })
}
export const updateHerbLastTimeViewed = (slug) => {
    return axios.get(`/api/herbs/update-last-time-viewed/${slug}`, getHeaders())
    .then(res => res)
    .catch(err => {
        throw err
    })
}