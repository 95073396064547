import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { SectionLabelOne, BreakpointTablet } from "../../Layout/GlobalStyle"
import HerbForProductItem from "../HerbForProductItem"

const Container = styled.div`
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;

  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 130px);
    grid-gap: 25px;
    width: 100%;
  }

  @media screen and (max-width: ${BreakpointTablet + "px"}) {
    padding-top: 0px;
    .products-section-title {
      font-size: 16px;
      padding-bottom: 15px;
    }

    .product-grid {
      grid-template-columns: repeat(auto-fill, minmax(137px, 1fr));
    }
  }
`

const LoadMore = styled.button`
  height: 34px;
  width: 100%;
  margin-top: 14px;
  font-size: 16px;
  font-family: var(--Font1);
  background: var(--Color2);
  color: white;
  text-transform: uppercase;
  border: 0px solid transparent;
`

const defaultLimit = 5

const HerbsInProduct = ({ herbs_used }) => {
  let renderHerbs = herbs_used.map((p, i) => {
    return <HerbForProductItem herbID={p} key={i} />
  })

  return (
    <Container>
      <SectionLabelOne paddingBottom={20}>HERBS IN PRODUCT</SectionLabelOne>
      <div className="product-grid">{renderHerbs}</div>
    </Container>
  )
}

HerbsInProduct.defaultProps = {
  ailment: {
    mongodb_id: "",
    name: "",
    _id: "",
  },
}

HerbsInProduct.propTypes = {
  ailment: PropTypes.shape({
    mongodb_id: PropTypes.string,
    _id: PropTypes.string,
    name: PropTypes.string,
  }),
}

export default HerbsInProduct
