import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import PropTypes from "prop-types"

import { PRODUCT } from '../components/Layout/routes'
import Product from '../components/Product'


 const  ProductTemplate = ({ pathContext: { productData } }) =>{

    let productPath = `${PRODUCT}/${productData.slug}`
    let title = productData.name
    let description = productData.description ? productData.description.substring(0, 150) : "This product has no description";


    var dollars = productData.price? productData.price / 100: 0.99
    dollars.toLocaleString("en-US", { style: "currency", currency: "USD" })

    const seoProps = {
        title: `${title} `,
        description: description,
        type: "website",
        pathname: productPath,
        image: {
            src: productData.picture,
            width: 1200,
            height: 628
        },
        productCategory: productData.category? productData.category: "Other",
        productPriceAmount: dollars,
        productPriceCurrency: "USD",
        productCondition: "new",
        productAvailability: "in stock",
        productBrand: productData.company,
        productRetailerItemID: productData.mongodb_id,
        imageLink: productData.picture,
        isProduct: true,
    }

    console.log(seoProps, 'this is seo props')
    return (
        <Layout seoProps={seoProps}>
            <div itemScope itemType="http://schema.org/Product">
  <meta itemProp="brand" content={productData.company} />
  <meta itemProp="name" content={title} />
  <meta itemProp="description" content={description} />
  <meta itemProp="productID" content={productData.mongodb_id} />
  <meta itemProp="url" content={`https://findanherb.com/product/${productData.slug}`} />
  <meta itemProp="image" content={productData.picture} />
  <div itemProp="value" itemScope itemType="http://schema.org/PropertyValue" >
    <span itemProp="propertyID" content="item_group_id"></span>
    <meta itemProp="value" content={productData.category}></meta>
  </div>
  <div itemProp="offers" itemScope itemType="http://schema.org/Offer">
    <link itemProp="availability" href="http://schema.org/InStock"/>
    <link itemProp="itemCondition" href="http://schema.org/NewCondition"/>
    <meta itemProp="price" content={dollars}/>
    <meta itemProp="priceCurrency" content="USD"/>
  </div>
</div>
           <Product data={productData} />
            
        </Layout>
    )
}



ProductTemplate.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}


export default ProductTemplate

