import React, {useState, useEffect} from 'react'
import styled from 'styled-components'
import { BreakpointTablet } from '../../Layout/GlobalStyle'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import { getHerbBySlug, getHerbMainPictureByHerbID, getHerbs } from '../../../services/herb.services';
import { HERB } from '../../Layout/routes'

const ProductItemWrapper = styled.div`
    display: block;
    position: relative;
    overflow: hidden;

    .out-bound-link{
        position: inherit;
    }
`


const ProductCarouselItem = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    ${({cursor = "pointer"}) => `cursor:  ${cursor};`}

    .product-image{
        width: 100%;
        height: 130px;
        border-radius: 4px;
        background-size: cover;
        position: relative;
        background-position: 50%;
        background-repeat: no-repeat;
        ${({opacity = 1}) => `opacity:  ${opacity};`}

        background-image: url(${props => props.theme.defaultHerbListIcon});      
        ${props => props.src ? `background-image: url(${props.src});` : `background-image: url(${props => props.theme.defaultHerbListIcon});`};
    }

    .product-title{
        font-size: 13px;
        color: #222220;
        font-family: var(--Font1);
        line-height: 20px;

        :hover{
            text-decoration: underline;
        }
    }

    .product-information{
        height: 40px;
        display: flex;
        align-items: center;
    }

    @media screen and (max-width: ${BreakpointTablet + 'px'}) {
        .product-image{
            height: 102px;   
        }

        

        padding: 6px;
    }

    :hover{
        .product-title{
            color: var(--Color2);
        }
        
    }

`

function HerbForProductItem({herbID}) {
    const [herb, setHerb] = useState({
        _id: "",
        name: "",
        slug: "",
        safeToPublish: false
    })
  const [loading, setLoading] = useState(false)
  const [picture, setPicture] = useState("")
  const [hasCheckedForPicture, setHasCheckedForPicture] = useState(false)

  useEffect(() => {
    let isSubscribed = true;
    if(isSubscribed && herbID){
      loadData(isSubscribed)
    }

    return () => isSubscribed = false;

  },[herbID])


  useEffect(() => {
    let isSubscribed = true;
    if(isSubscribed && herbID){
      loadPicture(isSubscribed)
    }

    return () => isSubscribed = false;

  },[!picture && !hasCheckedForPicture && herbID])

  const loadData = (isSubscribed) => {
    setLoading(true)
    getHerbs(`?id=${herbID}`)
        .then(res => {
            if (res.status === 200 && isSubscribed) {
                setLoading(false)
                setHerb(res.data)
            }
        })
        .catch(err => {
            setLoading(false)
            throw err
        })
}


console.log(herb, 'this is herb in herbForProductItem')

const loadPicture = (isSubscribed) => {
    setLoading(true)
    getHerbMainPictureByHerbID(herbID)
        .then(res => {
            if (res.status === 200 && isSubscribed) {
                setLoading(false)

                setPicture(res.data)

            
            setHasCheckedForPicture(true)

            }
        })
        .catch(err => {
            setHasCheckedForPicture(true)
            setLoading(false)
            throw err
        })
}




  return (
    <ProductItemWrapper>

    {herb.safeToPublish? <Link to={`${HERB}/${herb.slug}`} >

    <ProductCarouselItem src={picture.src}  >
        <div className="product-image">
            {picture?  <img style={{opacity: 0, width: "inherit", height: "inherit"}} alt={herb && herb.name && herb.name.substring(0, 100)} src={picture} /> : ""}
            <div className="color-overlay">

            </div>
        </div>
        <div className="product-information">
            <div className="product-title">
                {herb.name}
            </div>
        </div>
    </ProductCarouselItem>
</Link>: <>
<ProductCarouselItem cursor="not-allowed" opacity={!herb.safeToPublish? 0.6: 1} src={picture.src}  >
        <div className="product-image">
            {picture?  <img style={{opacity: 0, width: "inherit", height: "inherit"}} alt={herb && herb.name && herb.name.substring(0, 100)} src={picture} /> : ""}
            <div className="color-overlay">

            </div>
        </div>
        <div className="product-information">
            <div className="product-title">
                {herb.name}
            </div>
        </div>
    </ProductCarouselItem>

</>}

</ProductItemWrapper>

  )
}

HerbForProductItem.propTypes = {
  item: PropTypes.shape ({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  })
}

export default HerbForProductItem
