import React from "react"
import PropTypes from "prop-types"
import { ProductItemPropTypes } from "../../../types/ProductItemPropTypes"
import {
  CallToAction,
  CompanyName,
  Container,
  GridColumnOne,
  GridColumns,
  GridColumnTwo,
  InfoWrapper,
  PriceContainer,
  ProductInfoColumn,
  ProductMainImage,
  ProductMainImageWrapper,
  ProductName,
  StyledDivider,
} from "./styles"
import BreadCrumb from "../common/BreadCrumb"
import { PageParagraphTypeOne, SectionLabelOne } from "../Layout/GlobalStyle"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import HerbsInProduct from "./HerbsInProduct"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import ReadMore from "../common/ReadMore"

function Product({ data: productData }) {
  let {
    amazonAffiliateLink,
    description,
    herbs_used,
    company,
    category,
    may_help_with_ailments,
    mongodb_id,
    name,
    picture,
    price,
  } = productData

  var dollars = price / 100
  dollars.toLocaleString("en-US", { style: "currency", currency: "USD" })

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          {
            title: "Products",
          },
          {
            title: category ? category : "Category",
          },
          {
            title: `${name.substring(0, 20)}...`,
          },
        ]}
      />
      <GridColumns>
        <GridColumnOne>
          <ProductMainImageWrapper>
            <ProductMainImage src={picture} />
          </ProductMainImageWrapper>
        </GridColumnOne>

        <GridColumnTwo>
          <ProductInfoColumn>
            <ProductName>{name}</ProductName>
            <CompanyName>{company}</CompanyName>
            <StyledDivider />
            {dollars ? (
              <>
                <PriceContainer>
                  <div className="price-label">Price:</div>
                  <div className="price-number">${dollars}</div>
                </PriceContainer>

                <StyledDivider />
              </>
            ) : null}
            <ReadMore text={description}>

            <PageParagraphTypeOne marginBottom={"0px"}>{description}</PageParagraphTypeOne>
            </ReadMore>

            <OutboundLink
              className="herb-button-outbound-link"
              hrefLang="en-us"
              href={amazonAffiliateLink.replace("http://", "https://")}
              target="_blank"
            >
              <CallToAction
                disableElevation
                color={"secondary"}
                variant={"contained"}
                startIcon={<ShoppingCartIcon />}
              >
                Buy From Amazon
              </CallToAction>
            </OutboundLink>
          </ProductInfoColumn>
        </GridColumnTwo>
      </GridColumns>
      <InfoWrapper>
        <HerbsInProduct herbs_used={herbs_used} />
      </InfoWrapper>
    </Container>
  )
}

Product.propTypes = {
  data: ProductItemPropTypes,
}

export default Product
