import { Button, makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import styled from 'styled-components'

const StyledDiv = styled.div`

:hover{
    text-decoration: underline;
}
`

const useStyles = makeStyles((theme) => ({
    root: {
        transition: "0.2s ease-in-out",
    },
  hidden: {
    display: "-webkit-box",
    WebkitLineClamp: 6,
    overflow: "hidden",
    WebkitBoxOrient: "vertical"
  }
}));
function ReadMore({ children, text }) {

  let disableButton = text.length < 500;

  const classes = useStyles();
  const [isHidden, setIsHidden] = useState(true);
  return (
    <div className={classes.root} style={{display: 'flex', flexDirection: 'column', marginBottom: 50, textAlign: 'left'}}>
      <div className={isHidden ? classes.hidden : null}>{children}</div>
      {!disableButton?
      <StyledDiv style={{fontSize: 13,  cursor: "pointer", textTransform: 'initial', textAlign: "left", paddingLeft: 0, paddingRight: 0, width: 100, color: "rgb(27, 136, 191)"}} size="small" onClick={() => setIsHidden(!isHidden)}>
        {isHidden ? "Read more..." : "Read less"}
      </StyledDiv>: null}
    </div>
  );
}

export default ReadMore;