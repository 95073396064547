import PropTypes from 'prop-types'
export const productCategoryTypes = ["Tincture", "Capsules & Powder", "Plants", "Wine", "Infusion", "Decoction", "Syrup", "Infused Oil", "Ointment", "Poultice", "Cream", "Compresses & Lotion", "Juice", "Cold Maceration", "Baths & Skin Wash", "Essential Oil", "Pessaries & Suppositories", "Gargles & Mouthwash", "Steam Inhalation", "Other"]

export const ProductItemPropTypes = PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    mongodb_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    _id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    slug: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    amazonAffiliateLink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    description: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    may_help_with_ailments: PropTypes.arrayOf(PropTypes.string),
    herbs_used: PropTypes.arrayOf(PropTypes.string),
    category: PropTypes.oneOf(productCategoryTypes),
    price: PropTypes.number.isRequired,
})

