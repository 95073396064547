import { Button, Divider } from '@material-ui/core'
import styled from 'styled-components'
import { BreakpointTablet, device } from '../Layout/GlobalStyle'

export const Container = styled.div`
  margin: auto;
  width: 100%;

  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  max-width: 1080px;
  /* border: 1px solid blue; */
  .not-clickable {
    &:hover {
      text-decoration: none !important;
    }
  }

  .ailment-link {
    text-decoration: underline;
  }

  /* .herb-description-and-cta{
        .herb-button-outbound-link{
            width: 100%;
        }
    } */
`

export const ProductName = styled.h1`
    font-size: 28px;
    font-family: var(--Font1);
    line-height: 34px;
    margin-bottom: 0px;
    margin-top: 0px;
`

export const CompanyName = styled.h6`
margin-top: 0px;
margin-bottom: 0px;
color: rgb(77, 77, 77);
font-family: var(--Font2);
`

export const ProductInfoColumn = styled.div`


`


export const ProductMainImageWrapper = styled.div`
display: flex;
justify-content: center;

`



export const HerbInfoSectionColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${props =>
    props.paddingTop ? `${props.paddingTop}px` : "25px;"};
  padding-bottom: ${props =>
    props.paddingTop ? `${props.paddingTop}px` : "initial;"};
`

export const StyledDivider = styled(Divider)`
    margin-top: 16px;
    margin-bottom: 16px;
`

export const PriceContainer = styled.div`
display: flex;

.price-label{
    font-family: var(--Font1NotCondensed);
    color: rgb(34, 34, 32);
    padding-right: 5px;
}

.price-number{
    color: var(--Color1);
    font-weight: bold;
    font-family: var(--Font1NotCondensed);
}

`

export const CallToAction = styled(Button)`
    font-family: var(--Font1NotCondensed);
    color: white;
    text-transform: initial;
    height: 50px;
     
     font-weight: bold;

`

export const HerbHeadingSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 100px;
  padding-top: 54px;
  padding-bottom: 44px;

  @media only screen and (max-width: ${BreakpointTablet + 300 + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export const ProductMainImage = styled.img`
    width: 300px;

    @media ${device.mobileL}{
        width: 250px;
        height: 300px;
        object-fit: contain;
    }
`

export const GridColumns = styled.div`
display: grid;
padding-top: 20px;
grid-template-columns: 0.6fr 1fr;
grid-gap: 50px;

@media ${device.tablet}{
    grid-template-columns: 1fr;
}

`

export const GridColumnOne = styled.div``

export const GridColumnTwo = styled.div``

export const InfoWrapper = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;
`

export const HerbInformationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-gap: 100px;
  /* padding-top: 54px;
    padding-bottom: 44px; */

  @media only screen and (max-width: ${BreakpointTablet + 300 + "px"}) {
    grid-template-columns: 1fr;
    grid-gap: 25px;
  }

  @media only screen and (max-width: ${BreakpointTablet + "px"}) {
    grid-template-columns: 1fr;
    padding-top: 20px;
    padding-bottom: 20px;
  }
`

export const HerbInformationColumnOne = styled.div``
export const HerbInformationColumnTwo = styled.div`
  position: relative;
  width: 100%;
  max-width: 95vw;
`

export const HerbCallToActionColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: inherit;

  .favorite-count-details {
    padding-top: 13px;
    padding-bottom: 13px;
    font-size: 11px;
    font-family: var(--Font1NotCondensed);
    text-align: center;
    .herb-favorite-amount {
      font-weight: bold;
      color: var(--Color1);
      font-size: 13px;
    }
  }

  .favorite-herb-column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .your-favorites-message {
    font-size: 14px;
    text-align: center;
    text-decoration: underline;

    a {
      width: inherit;
      font-weight: bold;
      color: var(--Color1);
      font-family: var(--Font1NotCondensed);
      text-align: center;

      :hover {
        color: var(--Color1Darker);
      }
    }
  }
  .cta-column {
    padding: 14px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    border: 1px solid #cccccc;
  }

  .cta-divider {
    width: 100%;
    background: #ccc;
    height: 1px;
    margin-top: 14px;
    margin-bottom: 14px;
  }

  .share-bar-column {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
  }

  .share-bar-label {
    padding-top: 0px;
    padding-bottom: 11px;
    font-family: "Roboto";
    font-size: 13px;
    color: #222220;
    font-weight: bold;
  }

  @media (max-width: ${BreakpointTablet + 300 + "px"}) {
    margin-top: 50px;
  }
`

export const HerbHeadingSection = styled.section`
  width: 100%;

  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
  .herb-name {
    font-family: var(--Font1);
    font-size: 28px;
    color: #222220;
    line-height: 32px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
  }

  .herb-latin-name {
    font-family: var(--Font2);
    font-size: 14px;
    color: #4d4d4d;
    margin-top: 0px;
    line-height: normal;
    margin-bottom: 0px;
  }

  .herb-info-names {
    padding-bottom: 17px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 16px;
  }

  .herb-description-and-cta {
    display: flex;
    flex-direction: column;
  }

  .herb-description {
    font-family: var(--Font2);
    line-height: 22px;
    font-size: 16px;
    color: #4d4d4d;
  }

  @media (max-width: ${BreakpointTablet + "px"}) {
    border-bottom: 1px solid transparent;
    padding-bottom: 23px;

    display: flex;
    flex-direction: column-reverse;

    .herb-name {
      font-size: 24px;
    }

    .herb-latin-name {
      font-size: 13px;
    }

    .herb-info-names {
      padding-bottom: 10px;
    }

    .herb-description {
      font-size: 13px;
    }

    .herb-description-and-cta {
      padding-bottom: 23px;
    }

    .herb-info-names {
    }
  }
`