import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";
import {Container, StyledTypography, StyledBreadCrumb, BreadTypography} from './styles'



function BreadCrumb({ title, crumbs, titleOnClick }) {
  let displayCrumbs = crumbs.map((crumb, index) => {
    if (crumb.path) {
      return (
        <Link key={index} to={crumb.path}>
            <BreadTypography>{crumb.title}</BreadTypography>
        </Link>
      );
    } else {
      return <BreadTypography key={index}>{crumb.title}</BreadTypography>;
    }
  });

  return (
    <Container>
      {crumbs.length ? (
        <StyledBreadCrumb>{displayCrumbs}</StyledBreadCrumb>
      ) : null}
    </Container>
  );
}

BreadCrumb.defaultProps = {
  crumbs: [
    {
      path: "",
      title: "",
    },
  ],
};

BreadCrumb.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      title: PropTypes.string,
    })
  ),
};

export default BreadCrumb;
